@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

html {
  height: 100%;
}
body {
  font-family: "Work Sans";
  height: 100%;
  background-image: url("../static/images/black_background.jpg");
}
#root {
  height: 100%;
}
.github:link, .github:visited {
  color: rgb(156, 4, 4);
  text-decoration: none;
}
.github:hover {
  transition: .2s;
  text-decoration: underline;
}
.github {
  color: rgb(156, 4, 4);
  font-size: 1.2em;
}

::-moz-selection { 
  background: rgb(73, 73, 73);
  color: #f1f1f1;
}
::selection { 
  background: rgb(73, 73, 73); 
  color: #f1f1f1;
}

.App {
  text-align: center;
  position: relative;
  min-height: 100%;
}
.nav_link {
  text-decoration: none;
  color: white;
}
.NavBar {
  background-color: #1F2127;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  padding-left: 10%;
  padding-right: 10%;
  width: 80%;
}
.NavItem a {
  padding: 10px;
}
.NavItem {
  background-color: #1F2127;
  display: flex;
  flex-direction: row;
  margin: 0px 0px;


  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* border: 2px solid green; */
}

.NavItem:hover {
  background-color: #464C5A ;
}

.Name {
  list-style: none;
  padding: 10px;
  width: 100%;
  margin-left: 0px;
  color: white;
  text-align: left;
  font-size: calc(10px + 2vmin);
}

.title {
  font-size: 2em;
  margin-top: 60px;
}
.text {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1em;
  font-weight: lighter;
  text-align: left;
  margin: auto;
}

.tech_title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  margin-top: 5px;
}
.content {
  /* border: 2px solid black; */
  height: 100%;
}
.slide-container {
  /* width: 60%; */
  margin: auto;
  /* border: 2px solid red; */
}
.slidee {
  height: inherit;
  width: 100%;
  /* border: 2px solid blue; */
}
img {
  /* max-width: 100%; */
  max-width: 100%;
  max-height: 100%;
}
#Projects, #Challenges, #Home {
  width: 50%;
}

#Projects, #Challenges, #Home, #Resume {
  margin:auto;
  background-color: #EEE;
  height: 100%;
  min-height: 80vh;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  padding-top: 20px;
}
#Resume {
  width: 50%;
}
.content_body {
  margin:auto;
}
.content_title {
  font-size: 4em;
  margin: 15px;
}

/**********************/
.embededpdf > div {
  display: flex;
  margin:auto;
}

.react-pdf__Document > div{
  margin:auto;
}
/* table of contents */
.toc_title {
  font-size: 1.5em;
}
.toc_c {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.toc {
  margin: 5px;
  padding: 10px;
}
.toc_c a {
  margin: 2.5px;
  padding: 7px;
}

.toc_item {
  display: flex;
}
.toc_item * {
  text-decoration: none;
}

.link {
  color:rgb(156, 4, 4);
  text-decoration: none;
  font-size:1.1em;
}
.link:hover {
  text-decoration: underline;
}
/**********************/


.footer {
    position: absolute;
    left:0;
    bottom:0;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;

    background-color: #1F2127;
    display: flex;
    flex-direction: row;
    color:#f1f1f1;

}

.footer_github {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: #f1f1f1;
  text-decoration: none;
}
.footer_github:hover {
  background-color: #464C5A;
}

.footer_text {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 80%;
  margin-left: 0px;
  text-align: left;
}